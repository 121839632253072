import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/logo.png";
import { useState } from "react";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  // listAll,
  // list,
} from "firebase/storage";
import { storage } from "../firebase/firebase.config";

const Admin = () => {
  const [imageUpload, setImageUpload] = useState(null);
  const [pdfName, setPdfName] = useState(null);
  // const [imageUrls, setImageUrls] = useState([]);
  // const imagesListRef = ref(storage, "images/");
  // const imageRef = ref(storage, `images/${pdfName}`);

  //toast
  const notify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  //upload handler
  const uploadFile = (event) => {
    event.preventDefault();
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${pdfName}.pdf`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref)
        // .then((url) => {
        //   setImageUrls((prev) => [...prev, url]);
        // })
        .then(notify("Uploaded Successfully!"));
    });
  };

  // //load data
  // const downloadPdf = () => {
  //   list(imageRef).then((response) => {
  //     getDownloadURL(imageRef).then((url) => {
  //       fetch(url).then((response) => {
  //         response.blob().then((blob) => {
  //           // Creating new object of PDF file
  //           const fileURL = window.URL.createObjectURL(blob);
  //           // Setting various property values
  //           let alink = document.createElement("a");
  //           alink.href = fileURL;
  //           alink.download = "ZA598716.pdf";
  //           alink.click();
  //         });
  //       });
  //     });
  //   });
  // };

  return (
    <div>
      <div className="h-screen flex flex-col items-center justify-center">
        <div className="flex items-center justify-end">
          <img className="w-1/2 md:w-1/3" src={logo} alt="logo" />
        </div>
        <div className="card w-96 bg-base-100 shadow-xl">
          <div className="card-body">
            <form
              onSubmit={uploadFile}
              className="form-control flex flex-col gap-4"
            >
              <span className="text-center font-bold text-xl">Upload PDF</span>
              <span className="pb-0">PDF Id</span>
              <input
                type="text"
                name="id"
                required
                onChange={(event) => {
                  setPdfName(event.target.value);
                }}
                placeholder="enter pdf id"
                className="input input-bordered"
              />
              <span>PDF File</span>
              <input
                type="file"
                name="file"
                required
                accept="application/pdf,application/vnd.ms-excel"
                onChange={(event) => {
                  setImageUpload(event.target.files[0]);
                }}
                className="file-input input-bordered w-full max-w-xs"
              />
              <button type="submit" className="btn bg-purple-600">
                Upload
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Admin;
