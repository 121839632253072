import React from "react";

const PdfModal = ({ downloadPdf, inputNumber }) => {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <p className="text-lg font-bold">{inputNumber}</p>
      <button className="btn bg-purple-800 btn-sm my-3" onClick={downloadPdf}>
        abrir
      </button>
    </div>
  );
};

export default PdfModal;
