import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/logo.png";
import { useState } from "react";
import { ref, getDownloadURL, list } from "firebase/storage";
import { storage } from "../firebase/firebase.config";
import ReCAPTCHA from "react-google-recaptcha";
import PdfModal from "./PdfModal";

const User = () => {
  const [inputNumber, setInputNumber] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const imageRef = ref(storage, `images/${inputNumber}`);
  const notify = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const handleChange = (event) => {
    setInputNumber(event.target.value + ".pdf");
  };

  // const clickHandler = () => {
  //   if (inputNumber === firstPdfNumber) {
  //     fetch("ZA598716.pdf").then((response) => {
  //       response.blob().then((blob) => {
  //         // Creating new object of PDF file
  //         const fileURL = window.URL.createObjectURL(blob);
  //         // Setting various property values
  //         let alink = document.createElement("a");
  //         alink.href = fileURL;
  //         alink.download = "ZA598716.pdf";
  //         alink.click();
  //       });
  //     });
  //   } else {
  //     notify("Enter a valid number!");
  //   }
  // };

  const toggleModal = () => {
    if (inputNumber === null) {
      return;
    }
    setShowModal(!showModal);
  };

  //load data
  const downloadPdf = () => {
    list(imageRef).then((response) => {
      getDownloadURL(imageRef)
        .then((url) => {
          let alink = document.createElement("a");
          alink.href = url;
          alink.download = `${inputNumber}`;
          alink.click();
          // notify("Download Successful!");
        })
        .catch((error) => {
          notify("Invalid number!");
        });
    });
  };

  return (
    <div>
      {!showModal ? (
        <section className="pt-12 md:px-24 md:py-9">
          <div className="flex items-center justify-end">
            <img className="w-1/2 pr-12 md:w-1/3" src={logo} alt="logo" />
          </div>
          <div className="flex items-center justify-center py-12">
            <div className="card w-96 bg-base-100 bordered">
              <div className="p-4">
                <h2 className="text-lg font-bold">
                  Consultar Documento de Procedimiento
                </h2>
                <p className="py-2">*Campos requeridos</p>
                <hr />
              </div>
              <div className="flex items-center justify-center">
                <div className="card w-10/12 bg-base-100 bordered shadow-xl">
                  <div className="card-body">
                    <h2 className="card-title">Datos de identificación</h2>
                    <div className="bg-red-100 p-2 rounded text-sm">
                      <i className="fa-solid fa-circle-info text-blue-300"></i>
                      <p className="pl-3">
                        Para consultar el documento emitido por el Ministerio de
                        Relaciones Exteriores Asuntos, llenar la identificación
                        del titular tal como se aparecen en el documento físico.
                      </p>
                    </div>
                    <div>
                      <p className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 pb-2">
                        Número de pasaporte
                      </p>
                      <input
                        type="text"
                        name="input"
                        placeholder="número de pasaporte"
                        onChange={handleChange}
                        className="input input-bordered input-sm input-info w-full max-w-xs"
                      />
                      <button
                        onClick={toggleModal}
                        className="btn bg-purple-800 btn-sm my-3"
                      >
                        <span>
                          <i className="fa-solid fa-magnifying-glass pr-2"></i>
                        </span>
                        <span> Buscar</span>
                      </button>
                      <ReCAPTCHA
                        // ref={recaptchaRef}
                        sitekey="6Lc7rt8jAAAAAH4kM_4dZTk6Mr9xkfMCmaqonsj1"
                        // onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section>
          <PdfModal
            downloadPdf={downloadPdf}
            inputNumber={inputNumber}
          ></PdfModal>
        </section>
      )}
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <p className="text-center text-sm pt-2 pb-2">
        Esta aplicación funciona mejor en Internet Explorer 8 (o superior)
        <i className="fa-brands fa-internet-explorer"></i>, Mozila Firefox
        <i className="fa-brands fa-firefox"></i> o Google Chrome
        <i className="fa-brands fa-chrome"></i> <br /> @2021 Ministerio de
        Relaciones Exteriores <br />
        @Versión 6.0.2
      </p>
    </div>
  );
};

export default User;
