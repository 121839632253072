// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyDPQfwYcugCzwI8kKLym8OWWLqt-1TDZiY",
  authDomain: "thepdfadmin.firebaseapp.com",
  projectId: "thepdfadmin",
  storageBucket: "thepdfadmin.appspot.com",
  messagingSenderId: "523634513556",
  appId: "1:523634513556:web:74ed2c96afa6e07061c681",
  measurementId: "G-02RNTFGS2C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
