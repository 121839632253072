import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Main from "../layouts/Main";
import User from "../components/User";
import Admin from "../components/Admin";
import PdfModal from "../components/PdfModal";
export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      // {
      //   path: "/",
      //   element: (
      //     <div className="h-screen flex items-center justify-center text-xl font-bold">
      //       <p>Error 404</p>
      //     </div>
      //   ),
      // },
      {
        path: "/tramites/enlinea/consultarEstadoSolicitud.xhtml",
        element: <User></User>,
      },
      {
        path: "/tramites/enlinea/consultarEstadoSolicitud.xhtml/admin",
        element: <Admin></Admin>,
      },
      {
        path: "/confirm",
        element: <PdfModal></PdfModal>,
      },
    ],
  },
  {
    path: "*",
    element: (
      <div className="h-screen flex items-center justify-center text-xl font-bold">
        <p>Error 404</p>
      </div>
    ),
  },
]);
